<nav class="navbar ">
    <div class="container-fluid">
        <!-- Logo -->
        <a [routerLink]="['']" class="logo">
            <img src="../../../assets/images/logo/logo.png" alt="Vöhk Logo" width="auto" height="60">
        </a>
        <!-- Dropdown -->
        <div class="dropdown">
            <button type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                {{ isMobileView && returnNombre() ? returnNombre().split('@')[0] : returnNombre() }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li *ngIf="returnLogged() === '1'">
                    <button class="dropdown-item" (click)="mostrarCerraduras()">Mis Comunidades</button>
                </li>
                <!-- Descomentar lineas 49 a 50 en comunidadesv2 y 179 a 181 en lockv2
                <li>
                    <button class="dropdown-item" (click)="TransferirLock()">Transferir Dispositivos</button>
                </li>
                -->
                <!--  Descomentar lineas 51 a 52 en comunidadesv2 y 182 a 184 en lockv2
                <li>
                    <button class="dropdown-item" (click)="TransferirHub()">Transferir Gateway</button>
                </li>
                -->
                <li class="dark-mode-toggle">
                    <span (click)="$event.stopPropagation()">Dark Mode</span>
                    <mat-slide-toggle name="darkMode" [(ngModel)]="darkMode" (click)="$event.stopPropagation()" (change)="updateDarkMode()"></mat-slide-toggle>
                </li>
                <li *ngIf="returnLogged() === '1'">
                    <button class="dropdown-item" (click)="cerrarSesion()">Cerrar Sesión</button>
                </li>
            </ul>
        </div>
    </div>
</nav>
<app-transfer-lock *ngIf="this.popupService.transferLock" style="z-index: 10;"></app-transfer-lock>
<app-transfer-hub *ngIf="this.popupService.transferHub" style="z-index: 10;"></app-transfer-hub>