<app-loader [loader]="isLoading"></app-loader>
<div class="container" *ngIf="popupService.delete" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        ¿Está seguro que desea eliminar {{popupService.elementType}}?
    </div>
    <div id="content">
        <p class="error">{{error}}</p>
    </div>
    <div id="actions">
        <button (click)="popupService.delete = false">No</button>
        <button (click)="delete()">Si</button>
    </div>
</div>
<div class="container" *ngIf="popupService.autorizar" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        ¿Está seguro que desea autorizar a {{popupService.elementType}}?
    </div>
    <div id="content">
    </div>
    <div id="actions">
        <button (click)="popupService.autorizar = false">No</button>
        <button (click)="autorizar()">Si</button>
    </div>
</div>
<div class="container" *ngIf="popupService.desautorizar" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        ¿Está seguro que desea desautorizar a {{popupService.elementType}}?
    </div>
    <div id="content">
    </div>
    <div id="actions">
        <button (click)="popupService.desautorizar = false">No</button>
        <button (click)="desautorizar()">Si</button>
    </div>
</div>
<div class="container" *ngIf="popupService.changeRemoteEnable" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Por favor confirme sus acciones
    </div>
    <div id="content">
        <div class="linea_alternative" style="gap:10px;margin-bottom:0px">
            <span class="input-container">
                Apertura Remota
            </span>
            <span class="input-container">
                <mat-slide-toggle [(ngModel)]="remoteEnableToggle"
                    (change)="remoteEnableToggleChange($event)"></mat-slide-toggle>
            </span>
        </div>
    </div>
    <div id="actions">
        <button (click)="popupService.changeRemoteEnable = false">Cancelar</button>
        <button (click)="cambiarRemoteUnlock()">Confirmar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.congelar" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        ¿Está seguro que desea congelar a {{popupService.elementType}}?
    </div>
    <div id="content">
    </div>
    <div id="actions">
        <button (click)="popupService.congelar = false">No</button>
        <button (click)="congelar()">Si</button>
    </div>
</div>
<div class="container" *ngIf="popupService.descongelar" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        ¿Está seguro que desea descongelar a {{popupService.elementType}}?
    </div>
    <div id="content">
    </div>
    <div id="actions">
        <button (click)="popupService.descongelar = false">No</button>
        <button (click)="descongelar()">Si</button>
    </div>
</div>
<div class="container" *ngIf="popupService.cambiarNombre" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Introduzca el nuevo nombre
    </div>
    <div id="content">
        <div class="form-area">
            <form class="common-form">
                Nombre
                <input type="text" class="form-input" name="name" [(ngModel)]="name" style="margin-bottom:0px" />
                <p class="error">{{error}}</p>
            </form>
        </div>
    </div>
    <div id="actions">
        <button (click)="popupService.cambiarNombre = false">Volver</button>
        <button (click)="cambiarNombre()">Guardar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.cambiarPeriodo" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Introduzca la nueva fecha
    </div>
    <div id="content">
        <div class="form-area">
            <form #editPeriod="ngForm" (ngSubmit)="cambiarPeriodo(editPeriod.value)" class="common-form">
                <div class="linea">
                    Periodo de inicio
                    <span class="input-container">
                        <mat-form-field appearance="fill">
                            <mat-label>Fecha</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" name="startDate" required="required"
                                ngModel (dateChange)="startTimePicker.open()">
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Hora</mat-label>
                            <input matInput [ngxTimepicker]="startTimePicker" name="startHour" required="required"
                                [format]="24" ngModel>
                        </mat-form-field>
                        <ngx-material-timepicker #startTimePicker [hoursOnly]="true"></ngx-material-timepicker>
                    </span>
                </div>
                <div class="linea">
                    Periodo de finalización
                    <span class="input-container">
                        <mat-form-field appearance="fill">
                            <mat-label>Fecha</mat-label>
                            <input matInput [matDatepicker]="endDatePicker" name="endDate" required="required" ngModel
                                (dateChange)="endTimePicker.open()">
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Hora</mat-label>
                            <input matInput [ngxTimepicker]="endTimePicker" name="endHour" required="required"
                                [format]="24" ngModel>
                        </mat-form-field>
                        <ngx-material-timepicker #endTimePicker [hoursOnly]="true"></ngx-material-timepicker>
                    </span>
                </div>
                <p class="error">{{error}}</p>
                <div id="actions">
                    <button (click)="popupService.cambiarPeriodo = false">Volver</button>
                    <button type="submit">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="container" *ngIf="popupService.editarPasscode" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Introduzca la nueva secuencia de números
    </div>
    <div id="content">
        <div class="form-area">
            <form class="common-form">
                <div class="linea">
                    Nuevo código
                    <span class="input-container">
                        <input type="password" class="form-input" name="passcodePwd" [(ngModel)]="passcodePwd"/>
                    </span>
                </div>
                <!--
                <div *ngIf="popupService.passcode.keyboardPwdType !== 1 && popupService.passcode.keyboardPwdType !== 2 && popupService.passcode.keyboardPwdType !== 3 && popupService.passcode.keyboardPwdType !== 4" class="linea">
                    Hora de finalización
                    <span class="input-container">
                        <mat-form-field appearance="fill">
                            <mat-label>Hora</mat-label>
                            <input matInput [ngxTimepicker]="endTimePicker" name="endHour" [format]="24" [(ngModel)]="endHour">
                        </mat-form-field>
                        <ngx-material-timepicker #endTimePicker [hoursOnly]="true"></ngx-material-timepicker>
                    </span>
                </div>
                -->
                <p class="error">{{error}}</p>
            </form>
        </div>
    </div>
    <div id="actions">
        <button (click)="popupService.editarPasscode= false">Volver</button>
        <button (click)="editarPasscode()">Guardar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.needGateway" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Función no permitida
    </div>
    <div id="content">
        Para utilizar esta función necesita conectar esta cerradura a un dispositivo gateway.
    </div>
    <div id="actions">
        <button (click)="popupService.needGateway = false">Ok</button>
    </div>
</div>
<div class="container" *ngIf="popupService.invalidLock" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Accesso Denegado
    </div>
    <div id="content">
        No tiene permiso para acceder a esta cerradura. Comuníquese con el administrador de la cerradura para solucionar este problema.
    </div>
    <div id="actions">
        <button (click)="popupService.invalidLock = false">Ok</button>
    </div>
</div>
<div class="container" *ngIf="popupService.newGroup" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Crear Comunidad
    </div>
    <div id="content">
        <div class="form-area">
            <form class="common-form">
                Nombre
                <input type="text" class="form-input" name="name" [(ngModel)]="name" />
                <p class="error">{{error}}</p>
            </form>
        </div>
    </div>
    <div id="actions">
        <button (click)="popupService.newGroup = false">Volver</button>
        <button (click)="crearGrupo()">Guardar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.addLockGROUP" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Agregar Cerradura a {{popupService.group.groupName}}
    </div>
    <div id="content" style="justify-content: flex-start; ">
        <table class="lock-table">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Agregar</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="popupService.locksWithoutGroup.length > 0; else noLocks">
                    <tr *ngFor="let lock of popupService.locksWithoutGroup">
                        <td>{{ lock.lockAlias }}</td>
                        <td style="text-align: center">
                            <input type="checkbox" (change)="toggleLockSelection(lock.lockId, lock.lockAlias)">
                        </td>
                    </tr>
                </ng-container>
                <ng-template #noLocks>
                    <tr>
                        <td colspan="2">No existen cerraduras disponibles para agregar a este grupo.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <p class="error">{{error}}</p>
    <div id="actions">
        <button (click)="popupService.addLockGROUP = false">Volver</button>
        <button (click)="addSelectedLocksToGroup()">Agregar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.removeLockGROUP" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Remover Cerradura de {{popupService.group.groupName}}
    </div>

    <div id="content">
        <table class="lock-table">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Remover</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="popupService.group.locks.length > 0; else noLocks">
                    <tr *ngFor="let lock of popupService.group.locks">
                        <td>{{ lock.lockAlias }}</td>
                        <td style="text-align: center">
                            <input type="checkbox" (change)="toggleLockSelection(lock.lockId, lock.lockAlias)">
                        </td>
                    </tr>
                </ng-container>
                <ng-template #noLocks>
                    <tr>
                        <td colspan="2">No existen cerraduras disponibles para remover de este grupo.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <p class="error">{{error}}</p>
    <div id="actions">
        <button (click)="popupService.removeLockGROUP = false">Volver</button>
        <button (click)="removeSelectedLocksFromGroup()">Remover</button>
    </div>
</div>
<div class="container" *ngIf="popupService.selectLocksForMultipleEkeys" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Seleccione Cerradura para compartir
    </div>
    <div id="content">
        <div class="form-area">
            <div *ngFor="let lock of ekeyService.currentLocks">
                <label>
                    <input type="checkbox" [value]="lock.lockId"
                        (change)="popupService.toggleLockSelection(lock.lockId, lock.lockAlias)"
                        [checked]="isLockSelected(lock.lockId)">
                    {{ lock.lockAlias }}
                </label>
            </div>
        </div>
    </div>
    <div id="actions">
        <button (click)="selectLocks()">OK</button>
    </div>
</div>
<div class="container" *ngIf="popupService.addRecipientsForMultipleEkeys" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Añadir Receptor(es)
    </div>
    <div id="content">
        <div class="form-area">
            <form #addRecipient="ngForm" class="common-form">
                <div *ngFor="let recipient of popupService.recipients; let i = index" class="recipient-pair" style="display: flex;">
                    <div>
                        <p>
                            Cuenta del Destinatario
                            <input type="text" class="form-input" required [(ngModel)]="recipient.username" name="recipientAccount{{i}}" (ngModelChange)="validateEmailPhone(recipient.username)">
                        </p>
                        <p>
                            Nombre de ekey
                            <input type="text" class="form-input" required [(ngModel)]="recipient.ekeyName" name="ekeyName{{i}}">
                        </p>
                    </div>
                    <div style="display: flex; flex-direction: column; justify-content: center;">
                        <button type="button" class="x-button" (click)="removeRecipientPair(i)">X</button>
                    </div>
                </div>
                <p class="error">{{error}}</p>
            </form>
        </div>
    </div>
    <div id="actions" style="justify-content: space-around;">
        <div>
            <button type="button" (click)="addRecipientPair()">+Añadir</button>
        </div>
        <div style="display:flex ;gap: 20px;">
            <button (click)="popupService.addRecipientsForMultipleEkeys = false">Volver</button>
            <button type="submit" (click)="confirmRecipients()">OK</button>
        </div>
    </div>
</div>
<div class="container" *ngIf="popupService.cardReader" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Acerque la tarjeta al lector
    </div>
    <div id="content">
        <img src="../../../assets/images/imagenCard.png">
    </div>
    <div id="actions">
        <button (click)="popupService.cardReader=false">Volver</button>
    </div>
</div>
<div class="container_sin_width" *ngIf="popupService.emailSuccess" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Se enviará el siguiente correo al usuario
    </div>
    <div id="content" style="background-color: rgb(245, 245, 245);">
        <textarea [(ngModel)]="emailMessage" rows="10" cols="50"></textarea>
        <!-- <div [innerHTML]="popupService.emailMessage"></div> -->
    </div>
    <div id="actions">
        <button (click)="cancelEmail()">Volver</button>
        <button (click)="copyToClipboard()">Copiar mensaje</button>
        <button (click)="saveEmail()">Confirmar</button>
    </div>
    <div *ngIf="isCopied" class="confirmation-message">
        El mensaje fue copiado.
    </div>
</div>
<div class="container" *ngIf="popupService.ekeySuccess" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        La eKey ha sido creada correctamente
    </div>
    <div id="actions">
        <button (click)="closeWindow()">Ok</button>
    </div>
</div>
<div class="container" *ngIf="popupService.ekeySuccess2" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Las eKeys se han creado correctamente
    </div>
    <div id="actions">
        <button (click)="closeWindow()">Ok</button>
    </div>
</div>
<div class="container" *ngIf="popupService.passcodeSuccess" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        El código ha sido creado correctamente
    </div>
    <div id="actions">
        <button (click)="closeWindow()">Ok</button>
    </div>
</div>
<div class="container" *ngIf="popupService.cardSuccess" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        La tarjeta ha sido creada correctamente
    </div>
    <div id="actions">
        <button (click)="closeWindow()">Ok</button>
    </div>
</div>
<div class="container" *ngIf="popupService.excelNameWindow" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Introduzca un nombre para el archivo
    </div>
    <div id="content">
        <div class="form-area">
            <form class="common-form">
                Nombre
                <input type="text" class="form-input" name="name" [(ngModel)]="name" />
                <p class="error">{{error}}</p>
            </form>
        </div>
    </div>
    <div id="actions">
        <button (click)="this.popupService.excelNameWindow=false">Cancelar</button>
        <button (click)="exportToExcel()">Exportar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.temporalPasscode" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Crear Invitación
    </div>
    <div id="content">
        <div class="form-area">
            <form class="common-form">
                <p>
                    Nombre de la Invitación
                    <input style="border-radius:20px;border:1px solid black" type="text" class="form-input" name="name" [(ngModel)]="name" />
                </p>
                <p>
                    Duración
                    <select #times name="temporalPasscodeDuration" [(ngModel)]="passcodeDuration" style="width: 238px; height: 35px; border-radius:20px;border:1px solid black;align-self:center;">
                        <option default value="">No seleccionado</option>
                        <option value="1">1 Hora</option>
                        <option value="2">2 Horas</option>
                        <option value="3">3 Horas</option>
                        <option value="12">12 Horas</option>
                    </select>
                </p>
                <p class="error">{{error}}</p>
            </form>
        </div>
    </div>
    <div id="actions">
        <button (click)="popupService.temporalPasscode = false">Volver</button>
        <button (click)="sharePasscode()">Continuar</button>
    </div>
</div>
<div class="container_50" *ngIf="popupService.temporalPasscode2" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Agregar Invitados
    </div>
    <div id="content">
        <div class="form-area">
            <form class="common-form2">
                <div class="person-container" *ngFor="let person of people; let i = index">
                    <div class="person-inputs">
                        <div class="person-number">
                            {{ i + 1 }}
                        </div>
                        <input style="border-radius:20px;border:1px solid black" type="text" class="form-input" [(ngModel)]="person.personName" (ngModelChange)="person.personName = $event" name="personName{{i}}" placeholder="Nombre del Invitado" />
                        <input style="border-radius:20px;border:1px solid black" type="text" class="form-input" [(ngModel)]="person.personEmail" (ngModelChange)="person.personEmail = $event" name="personEmail{{i}}" placeholder="Correo" />
                        <button type="button" class="remove-button" (click)="removePerson(i)">X</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div id="actions">
        <button (click)="closeSharePasscode()">Volver</button>
        <button type="button" (click)="addPerson()">Agregar Invitado</button>
        <button (click)="createTemporalPasscode()">Enviar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.selectLocksForEkey" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Seleccione las cerraduras
    </div>
    <div id="content" style="justify-content: flex-start; ">
        <table class="lock-table">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Seleccionado</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="popupService.locksOfGroup.length > 0; else noLocks">
                    <tr *ngFor="let lock of popupService.locksOfGroup">
                        <td>{{ lock.lockAlias }}</td>
                        <td style="text-align: center">
                            <input type="checkbox" [checked]="isLockSelected(lock.lockId)" (change)="toggleLockSelection(lock.lockId, lock.lockAlias)">
                        </td>
                    </tr>
                </ng-container>
                <ng-template #noLocks>
                    <tr>
                        <td colspan="2">No existen cerraduras disponibles para agregar a este grupo.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <p class="error">{{error}}</p>
    <div id="actions">
        <button (click)="popupService.selectLocksForEkey = false">Volver</button>
        <button (click)="this.popupService.selectLocksForEkey = false; confirmLockSelection()">Confirmar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.selectLocksForTransfer" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Seleccione las cerraduras
    </div>
    <div id="content" style="justify-content: flex-start; ">
        <table class="lock-table">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Seleccionado</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="lockService.adminLocks.length > 0; else noLocks">
                    <tr *ngFor="let lock of lockService.adminLocks">
                        <td>{{ lock.lockAlias }}</td>
                        <td style="text-align: center">
                            <input type="checkbox" [checked]="isLockSelected(lock.lockId)" (change)="toggleLockSelection(lock.lockId, lock.lockAlias)">
                        </td>
                    </tr>
                </ng-container>
                <ng-template #noLocks>
                    <tr>
                        <td colspan="2">No existen cerraduras disponibles para transferir.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <p class="error">{{error}}</p>
    <div id="actions">
        <button (click)="popupService.selectLocksForTransfer = false">Volver</button>
        <button (click)="this.popupService.selectLocksForTransfer = false; confirmLockSelection()">Confirmar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.selectHubsForTransfer" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Seleccione los Gateways
    </div>
    <div id="content" style="justify-content: flex-start; ">
        <table class="lock-table">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Red</th>
                    <th>N° Dispositivos</th>
                    <th>Seleccionado</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="gatewayService.gateways.length > 0; else noLocks">
                    <tr *ngFor="let hub of gatewayService.gateways">
                        <td>{{ hub.gatewayName }}</td>
                        <td>{{ hub.networkName }}</td>
                        <td>{{ hub.lockNum }}</td>
                        <td style="text-align: center">

                            <input type="checkbox" [checked]="isHubSelected(hub.gatewayId)" (change)="toggleHubSelection(hub.gatewayId)">

                        </td>
                    </tr>
                </ng-container>
                <ng-template #noLocks>
                    <tr>
                        <td colspan="2">No existen gateways disponibles para transferir.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <p class="error">{{error}}</p>
    <div id="actions">
        <button (click)="popupService.selectHubsForTransfer = false">Volver</button>
        <button (click)="this.popupService.selectHubsForTransfer = false; confirmLockSelection()">Confirmar</button>
    </div>
</div>
<div class="container" *ngIf="popupService.esencial" [ngClass]="{'dark-mode': DarkModeService.darkMode}">
    <div class="header">
        Datos esenciales de la cerraduras
    </div>
    <div id="content">
        <div class="linea_alternative">
            <span class="input-container">
                Número de la cerradura
                {{popupService.detalles.lockName}}
            </span>
        </div>
        <div class="linea_alternative">
            <span class="input-container">
                MAC
                {{popupService.detalles.lockMac}}
            </span>
        </div>
        <div class="linea_alternative">
            <span class="input-container">
                LockID
                {{popupService.detalles.lockId}}
            </span>
        </div>
        <div class="linea_alternative">
            <span class="input-container">
                Firmware
                {{popupService.detalles.firmwareRevision}}
            </span>
        </div>
    </div>
    <div id="actions">
        <button (click)="popupService.esencial= false">Ok</button>
    </div>
</div>