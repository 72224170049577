<div class="pagina_completa">
    <app-loader [loader]="isLoading"></app-loader>
    <div class="top-right-buttons">
        <button class="letter-button" onclick="window.location.href='https://www.vohk.cl/'">Home</button>
        <button class="letter-button" [matMenuTriggerFor]="contacto">Contacto</button>
        <mat-menu #contacto="matMenu" [class]="'dropdown'">
            <p style="margin: 10px;">Telefono: +56 9 7408 1594</p>
            <p style="margin: 10px;">Email : contacto&#64;vohk.cl</p>
        </mat-menu>
    </div>
    <div class="background-image">
        <div class="login-container">
            <form #userSignIn="ngForm" (ngSubmit)="login(userSignIn.value)" class="cuadro">
                <div class="logo-container">
                    <div class="bienvenido">
                        Bienvenido
                        <img src="../../../assets/images/hand_wave.png" alt="Image Description" style="height: 1em; vertical-align: baseline;">
                    </div>
                    <div class="subtitle">
                        Administra los accesos de forma rápida y eficiente
                    </div>
                </div>
                <div style="margin-left:30px;margin-right:30px;">
                    <div class="login_texto">
                        Email / Celular
                    </div>
                    <div style="display:flex;justify-content:center;">
                        <input type="text" class="login_input" name="username" required="required" ngModel/>
                    </div>
                </div>
                <div style="margin-left:30px;margin-right:30px;">
                    <div class="login_texto">
                        Contraseña
                    </div>
                    <div class="password-input">
                        <input type="{{ showPassword ? 'text' : 'password' }}" class="login_input" name="password" required="required" ngModel/>
                        <button type="button" (click)="togglePasswordVisibility()">
                            <fa-icon [icon]="showPassword ? faEye : faEyeSlash"></fa-icon>
                        </button>
                    </div>
                </div>
                <p class="login_error">{{loginError}}</p>
                <div style="display:flex;justify-content:center;">
                    <button class="login_boton" type="submit">Iniciar Sesión</button>
                </div>
                <div class="texto_vohk">
                    <img class="imagen_vohk" src="../../../assets/images/logo/logo_vohk_texto_negro.jpeg" alt="black text logo">
                    <div class="instrucciones">
                        Usa las mismas credenciales de la app móvil. Solo disponible para administradores principales o secundarios.
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>